<template>
  <div class="mbl-msform msForm">
    <v-row>     
      <v-col cols="12" sm="12">
        <label>Type</label>
        <v-select
          outlined
          v-model="notificationitem.type"
          :items="noficationtype"
        ></v-select>
      </v-col>
      <v-col cols="12">
        <label>Message</label>
        <mavon-editor language="en" v-model="notificationitem.message" outlined
          name="input-7-1"
          rows="3" />
          <span v-if="!notificationitem.message && !isValidForm" style="color:red;">Message is required</span> 
      </v-col>
      <v-col cols="12">
        <label>Version</label>
        <v-text-field
          outlined
          placeholder="version"
          v-model.trim="notificationitem.version"
        ></v-text-field>
        <span v-if="!notificationitem.version && !isValidForm" style="color:red;">Version is required</span>
      </v-col>      
    </v-row>
    
  </div>
</template>

<script>
export default {
  name: "addNotificationInfo",
  props: ["notificationitem","isValidForm"],
  data: () => ({
    iconlist: [],    
    noficationtype: ["notify", "version"],
  }),
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>