<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>Prompt</h3>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text class="px-6" v-if="editpropmt == false">
      <pre>{{promptvalue}}</pre>
    </v-card-text>
    <v-card-text class="px-6" v-if="editpropmt == true">
      <v-col cols="12">
        <label>Edit Prompt</label>
        <mavon-editor language="en" v-model="promptvalue" outlined :subfield="false" 
          name="input-7-1"
          rows="3" />
      </v-col> 
    </v-card-text>
    <v-divider></v-divider>
    
    <v-card-actions v-model="closeDialog">
      <v-spacer></v-spacer>
      <div class="pl-10 py-5">       
        <v-btn class = "nocap" @click="cancelPrompt()" text>
          <span class="pl-2">Cancel</span>
        </v-btn>
        <v-btn v-if ="editpropmt == false" class="nocap primary" text @click="editpropmt = true">
          <span class="pl-2">Edit</span>
        </v-btn>
        <v-btn v-if ="editpropmt == true" class="nocap primary" text @click="save()">
          <span class="pl-4">Save</span>
        </v-btn>
      </div>
    </v-card-actions>
    <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="SuccessMsgDialog"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-img
                    style="z-index: 1"
                    alt="sentient logo"
                    class="d-flex align-center justify-center"
                    src="@/assets/tick-mark1.svg"
                    contain
                    max-width="192"
                  ></v-img>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <!-- <v-icon color="primary" class="text-center" style="font-size:60px">mdi-check-circle </v-icon> -->

                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Success</div>
                  </v-card-title>
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap" color="primary" @click="SuccessMsgDialog = false">Done </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
    <v-row justify="center">
    <v-dialog
        class="msPop"
        style="box-shadow: none; background-color: transparent"
        v-model="confirmPromptDialog"
        persistent
        max-width="388"
        content-class="vDialog"
    >
        <v-card
        class="d-flex flex-column pa-0 align-center justify-center"
        flat
        style="background-color: transparent"
        >
        <v-card-text
            class="text-center white rounded-lg"
            style="height: 200px"
        >
            <v-card-title class="text-h5 text-bold d-flex justify-center">
            <div class="mt-4"><span style="color: red; font-size: 30px;" class="mdi mdi-alert-circle-outline"></span> Warning Notification</div>
            </v-card-title>
            Are you sure want to update prompt and it will affect immediiately in prompt response?
            <v-card-actions>
            <div class="text-center mt-4">
                <v-btn class="nocap mx-5" text @click="confirmPromptDialog = false">Cancel</v-btn>
                <v-btn class="nocap primary" @click="sumbitPrompt()">Save </v-btn>
            </div>
            </v-card-actions>
        </v-card-text>
        </v-card>
    </v-dialog>
    </v-row>
  </v-card>
  

</template>

<script>
import API_Util from "../services/util/API_Util.js";

export default {
  name: "editFun",
  props: ['newItems', 'response_msg', 'response_status'],

  data: () => ({
    closeDialog: false,
    response_msg: "",
    response_status: "",
    panel: [0],
    apiKey: "",
    owner: "",
    user: "",
    confirmPromptDialog : false,
    SuccessMsgDialog :false,
    editpropmt : false,
    promptvalue:"",
  }),
  created(){
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.user = sessionObj.session.id;
    this.owner = localStorage.getItem('owner');
    this.chatprompt()
  },
  methods: {
    closeoutageEdit() {
      this.$emit('closeoutageEdit', false);
    },
    save(){
        this.editpropmt =false;
        this.confirmPromptDialog = true;
        const headers = {
          "Content-Type": "application/json",
          "x-api-key": this.apiKey,
        };
        var data = {"prompt_text": this.promptvalue,"created_by":"admin"}
        API_Util.URLS.axios
          .post(API_Util.URLS.updateprompt , data , { headers })
          .then((response) => {
            var resp = response.data;
            console.log(resp)
            if (resp.message == "Successfully Updated" ){
              this.response_message = "Successfully Updated";
              this.successMessage = true;
            } else {
              this.response_msg = "Something Error";
              this.response_status = "Error";
                setTimeout(() => {                
                  this.response_msg = "";
                }, 2000);
            }
            setTimeout(() => {
              this.chatprompt()
            }, 3000)
          });                                   

    },
    sumbitPrompt(){

        this.confirmPromptDialog = false;
        this.SuccessMsgDialog = true;

    },
    cancelPrompt(){
        this.editpropmt =false;
    },
    chatprompt(){
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.apiKey,
      };
      API_Util.URLS.axios
        .post(API_Util.URLS.chatpropmt , {}, { headers })
        .then((response) => {
          console.log(response)
          this.promptvalue = response.data.prompt_text;
          console.log(this.promptvalue)
          this.loader = false;
        });
    },

    clearObjectData() {
      this.closeDialog = false;
      this.response_msg = "";
    },
  },
};
</script>

<style lang="scss">
</style>