<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>Outage Details</h3>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text>
      <div class="px-10 msForm">
         <!-- -----------Add Data Schema------------>
        <v-col cols="12" class="px-0 py-6">
          <h2>Outage Details</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" sm="4">
            <label class="my-4">Status</label>
            <div>{{rowoutageItem.status}}</div>
          </v-col>
          <v-col cols="12" sm="4">
            <label class="my-4">Start Date</label>
            <div>{{rowoutageItem.start_date_time}}</div>
          </v-col>
          <v-col cols="12" sm="4">
            <label class="my-4">End Date</label>
            <div>{{rowoutageItem.end_date_time}}</div>
          </v-col>
          <v-col cols="12" sm="12">
            <label class="my-4">Outage</label>
            <div>{{rowoutageItem.outage}}</div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <!-- <v-divider></v-divider> -->
    <v-card-actions>
      <v-spacer></v-spacer>
         <div class="py-5">
      <v-btn class="nocap" @click="closeDetail()" text>
        <span class=""> Cancel</span>
      </v-btn>
      <v-btn class="nocap primary" text @click="outageeditopen()">
        <span class="">
          <v-icon>mdi-square-edit-outline</v-icon>
          Edit
        </span>
      </v-btn>
         </div>
    </v-card-actions>       
  </v-card>
</template>

<script>

export default {
  name: "detailoutage",
  props: ['rowoutageItem',],
 
  data: () => ({
    text: "",
  }),
  methods: {
    outageeditopen(){
      this.$emit('closeDetail', false)
    },
    closeDetail() {
      this.$emit('outageeditopen', false)
    },
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>