<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>Edit Notifications</h3>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text class="px-6">
      <v-form>
        <addNotificationInfo :notificationitem="notificationitem" />
      </v-form>  
    </v-card-text>
    <v-divider></v-divider>
    
    <v-card-actions v-model="closeDialog">
      <v-spacer></v-spacer>
      <div class="pl-10 py-5">       
        <v-btn class="nocap" @click="closenotifyEdit" text>
          <span class="pl-2">Cancel</span>
        </v-btn>
        <v-btn class="nocap primary" text @click="save">
          <span class="pl-2">Update</span>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import addNotificationInfo from "./notifyInfo/addNotificationInfo.vue";
import API_Util from "../services/util/API_Util.js";

export default {
  components: {
    addNotificationInfo,
  },
  name: "editnotification",
  props: ['notificationitem', 'response_msg', 'response_status'],

  data: () => ({
    closeDialog: false,
    response_msg: "",
    response_status: "",
    panel: [0],    
    apiKey: "",
    owner: "",
    user: "",
  }),
  created(){
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.user = sessionObj.session.id;
    this.owner = localStorage.getItem('owner');
  },
  methods: {
    closenotifyEdit() {
      this.$emit('closenotifyEdit', false);
    },

    clearObjectData() {
      this.closeDialog = false;
      this.response_msg = "";
    },

  save () {
      this.$emit('saveFunEditedData', this.notificationitem);
    },
    getFunctionList(){
      const headers = { "Content-Type" : "application/json", "x-api-key" : this.apiKey};
      API_Util.URLS.axios.post(API_Util.URLS.functionList, {headers})
      .then((response) => {
        console.log(response.data.results)
      })
    },

    onUpdate(text) {
      this.text = text;
    },
    onChangefile(fieldname, imageref) {
      this[fieldname] = [...this.$refs[fieldname].files];

      if (fieldname != "imagelist" && this[fieldname].length > 1) {
        this[fieldname].splice(0, this[fieldname].length - 1);
      }

      for (let i = 0; i < this[fieldname].length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          this.$refs[`${imageref}` + parseInt(i)][0].src = reader.result;
        };
        reader.readAsDataURL(this[fieldname][i]);
      }
    },
  },
};
</script>

<style lang="scss">
.chevron-down-circle-outline {
  color: red;
}
.v-expansion-panel-header__icon .v-icon {
  color: red !important;
}

.theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color:  var(--v-secondary-darken4) !important;
  border: 1px solid  var(--v-secondary-darken1) !important;
  border-radius: 100% !important;
}
</style>