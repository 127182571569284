<template>
  <div class="d-flex flex-column margin-top" style="height: 100%">
    <div class="flex-fill  main-credit">
      <v-container>
        <div class="pt-6">
          <div class="headline head-com-1 mb-1 font-weight-bold">
            {{ labels.TITLE }}
          </div>
          <v-row class="gp1">
            <div class="pr-4 pb-0 col-md-12 col-sm-12 padding-align">
              <h4 class="title" v-if="orgType == 'main'">
                {{ accountData.orgName }} {{ accountData.acNumber }}
                {{ accountData.acType }} {{ labels.ACC_TXT }}
              </h4>
              <h4 class="title" v-else>
                {{ accountData.acNumber }} {{ accountData.acType }}
                {{ labels.ACC_TXT }}
              </h4>
            </div>
          </v-row>
        </div>
        <v-row class="gp1 dropheight">
          <div class="col-12 pa-0">
            <div class="d-flex flex-md-row flex-column mt-4">
              <div class="d-flex flex-md-row flex-column">
                <div class="col-12 col-md-4 padding-align" v-if="orgType == 'main' && !teamspace">
                <h4 class="label-1 padding" >Select Owner:</h4>
                  <v-autocomplete
                    class="dropdown"
                    flat
                    depressed
                    v-model="ownerId"
                    :items="ownerList"
                    item-text="ownerName"
                    item-value="id"
                    solo
                    v-on:change="getOrgList()"
                  ></v-autocomplete>
                </div>
                <div class="pr-md-2 col-12"
                  :class="orgType == 'main' && !teamspace ? 'col-md-4' : 'col-md-6'" v-if="orgType == 'main'">
                  <h4 class="label-1 padding">Select Organisation:</h4>
                  <v-autocomplete
                    class="dropdown"
                    flat
                    depressed
                    v-model="orgId"
                    :items="orgList"
                    item-text="name"
                    item-value="id"
                    solo
                    v-on:change="getOrgAccount()"
                  ></v-autocomplete>
                </div>
                <div
                  class="pr-md-2 col-12"
                  :class="orgType == 'main' && !teamspace ? 'col-md-4' : 'col-md-6'"
                >
                <h4 class="label-1 padding">Select Type:</h4>
                  <v-select
                    class="dropdown"
                    flat
                    depressed
                    :items="creditTypes"
                    item-text="value"
                    item-value="code"
                    v-model="creditType"
                    v-on:change="resultQuery"
                    solo
                  ></v-select>
                </div>
              </div>
              <v-spacer></v-spacer>
              <div class="align_items" style= "display:flex; align-items:end" >
                <div class="col-12 topnav">
                  <div class="pa-1">
                    <v-btn
                      @click="
                        promo = true;
                        promoerror = false;
                        promoCodeApplied = false;
                        promocode = '';
                      "
                      class="text-capitalize pa-1 sen-text-chocolate-1 text-decoration-underline"
                      text
                      color="default-btn"
                      >{{ labels.PROMO_MSG_TIT }}</v-btn
                    >
                  </div>
                  <!-- <div class="pa-1 crd-btns">
                    <v-btn
                      v-if="orgType == 'main'"
                      class="text-capitalize secondary darken-3 white--text"
                      color="default-btn"
                      @click="goTopup()"
                      >{{ labels.CE_TOP_CRED }}</v-btn
                    >
                    <v-btn
                      v-else
                      class=" text-capitalize secondary darken-3 white--text"
                      color="default-btn"
                      @click="goTopup()"
                      >{{ labels.CE_TOPUP }}</v-btn
                    >
                  </div> -->
                  <div class="pa-1 crd-btns" v-if="orgType == 'main' && !teamspace">
                    <v-btn
                      class="text-capitalize secondary darken-3 white--text"
                      color="default-btn"
                      @click="dialog = true"
                      >{{ labels.ADD_CREDIT_BTN }}</v-btn
                    >
                  </div>
                  <div class="py-1 pl-1 pr-1 crd-btns">
                    <v-btn
                      class="text-capitalize secondary darken-3 white--text"
                      color="default-btn"
                      @click="showActive = false"
                      v-if="showActive"
                      >{{ labels.EXPIRED_CREDITS }}</v-btn
                    >
                    <v-btn
                      class="text-capitalize secondary darken-3 white--text"
                      color="default-btn"
                      v-else
                      @click="showActive = true"
                      >{{ labels.ACTIVE_CREDITS }}</v-btn
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-row>

        <v-row>
          <div class="col-sm-12 padding-align">
            <v-data-table
              :mobile-breakpoint="0"
              :hide-default-footer="true"
              :headers="headers"
              :disable-sort="true"
              item-key="code"
              class="elevation-0  box invoice-table table-left-align action-free creditmng_table"
            >
              <template v-slot:body="">
                <tbody>
                  <tr
                    v-for="(actcredit, index) in filterListActive"
                    :key="index"
                    v-show="showActive"
                  >
                    <td>
                      <span
                        v-if="actcredit.creditType == 'ADD_CRE_MARK_CRED'"
                        >{{ labels.MARKETING_CREDIT }}</span
                      >
                      <span v-else>{{ labels.CREDIT_TOP_UP }}</span>
                    </td>
                    <td>{{ actcredit.description }}</td>
                    <td>{{ actcredit.startDate }}</td>
                    <td>
                      <span
                        :class="
                          getExpiryDate(actcredit.expiryDate).color
                            ? 'sen-text-orange'
                            : ''
                        "
                        >{{ getExpiryDate(actcredit.expiryDate).date }}</span
                      >
                    </td>
                    <td class="text-right">{{ actcredit.credit | formatNumber2 }}</td>
                    <td class="text-right">{{ actcredit.reCredit | formatNumber }}</td>
                    <td>{{ index + 1 }}</td>
                  </tr>
                  <tr
                    v-for="(excredit, index) in filterListExpiry"
                    :key="index"
                    v-show="!showActive"
                  >
                    <td>
                      <span v-if="excredit.creditType == 'ADD_CRE_MARK_CRED'">{{
                        labels.MARKETING_CREDIT
                      }}</span>
                      <span v-else>{{ labels.CREDIT_TOP_UP }}</span>
                    </td>
                    <td>{{ excredit.description }}</td>
                    <td>{{ excredit.startDate }}</td>
                    <td class="sen-text-red">
                      {{ excredit.expiryDate }} {{ labels.CREDIT_EXP }}
                    </td>
                    <td class="text-right">{{ excredit.credit | formatNumber2 }}</td>
                    <td class="text-right">{{ excredit.reCredit | formatNumber }}</td>
                    <td>{{ index + 1 }}</td>
                  </tr>
                  <tr v-if="showActive && filterListActive == 0 && overlay==false">
                    <td colspan="7" class="text-center icon-position">
                      <!-- {{ labels.NOT_FOUND }} -->
                      
        <div class=" text-center d-flex flex-column align-content-space-between">
          <div class="d-flex justify-center align-center ">
          <div>
              <v-icon class="mr-2 text-color display-2 ">mdi-telescope</v-icon>
              </div>
              <div class="text-color">
            {{ labels.NO_DATA_FND }}
              </div>
        </div>
          <!-- <div>
              <p class="primarytextcolor--text text--darken-2 mb-2">{{ labels.NO_DATA_FND_MSG }}</p>
          </div> -->
        </div>
                    </td>
                  </tr>
                  <tr v-else-if="!showActive && filterListExpiry == 0 && overlay==false">
                    <td colspan="7" class="text-center icon-position">
                      <!-- {{ labels.NOT_FOUND }} -->
                      <div class="text-center d-flex flex-column align-content-space-between">
          <div class="d-flex justify-center align-center">
          <div>
              <v-icon class="mr-2 text-color display-2">mdi-telescope</v-icon>
              </div>
              <div class="text-color">
            {{ labels.NO_DATA_FND }}
              </div>
        </div>
          <!-- <div class="pb-12">
            <p class="primarytextcolor--text text--darken-2 mb-2">{{ labels.NO_DATA_FND_MSG }}</p>
          </div> -->
        </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>

            

            
          </div>
        </v-row>
        <v-row >
          <div >
            <v-dialog v-model="dialog" persistent max-width="600px">
              <v-card class="px-6 py-4 dialog-content">
                <v-card-title> </v-card-title>
                <v-card-text>
                  <h4 class="text-align-center align-center justify-center">
                    <div
                      class="headline head-com-1 mb-1 font-weight-bold text-center"
                    >
                      {{ labels.MDL_ACC_BAL }}
                    </div>
                  </h4>
                  <v-row class="gp1">
                    <div class="col-12 pa-0">
                      <h4 class="label-1">
                        {{ labels.MDL_ITEMS }} <span class="sen-text-red">*</span>
                        <span
                          v-show="errorForm && !creditType"
                          class="sen-text-red"
                          >{{ labels.MDL_CRED_TYP_REQ }}</span
                        >
                      </h4>
                      <v-select
                        class="dropdown"
                        flat
                        depressed
                        v-model="creditType"
                        :items="itemTypes"
                        item-text="value"
                        item-value="code"                     
                        solo
                        required
                      ></v-select>
                    </div>
                    <div class="col-12 pa-0 add-credit-popup">
                      <h4 class="label-1">
                        {{ labels.MDL_CREDIT_TXT }}
                        <span class="sen-text-red">*</span>
                        <span
                          v-show="errorForm && credit == 0"
                          class="sen-text-red"
                          >{{ labels.MDL_CREDIT_DATE_REQ }}</span
                        >
                      </h4>
                      <v-text-field
                        class="dateRange transparent"
                        solo
                        flat
                        placeholder="0"
                        v-model="credit"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                      ></v-text-field>
                    </div>
                    <div
                      class="col-12 pa-0 add-credit-popup"
                      v-show="creditType == 'ADD_CRE_MARK_CRED'"
                    >
                      <h4 class="label-1">Start Date</h4>
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            class="dateRange transparent labelcolor"
                            v-model="startDate"
                            solo
                            flat
                            label="Select Start Date"
                            append-icon="mdi mdi-calendar-today"
                            v-on:click:append="menu1 = true"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          class="custom-datePicker"
                          ref="picker"
                          v-model="date1"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                      <h4 class="label-1">{{ labels.MDL_EXP_DATE_TXT }}</h4>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            class="dateRange transparent labelcolor"
                            v-model="expiryDate"
                            solo
                            flat
                            label="Select Expiry Date"
                            append-icon="mdi mdi-calendar-today"
                            v-on:click:append="menu = true"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          class="custom-datePicker"
                          ref="picker"
                          v-model="date"
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                    <div class="col-12 pa-0 add-credit-popup">
                      <h4 class="label-1">{{ labels.MDL_DESP_TXT }}</h4>
                      <v-textarea
                        class="dateRange transparent labelcolor"
                        solo
                        flat
                        auto-grow
                        v-model="description"
                      ></v-textarea>
                    </div>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="default-btn"
                    class="text-capitalize darken-3 primarytextcolor--text"
                    text
                    @click="
                      (creditType = ''), (credit = '');
                      //startDate= new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate();
                      startDates();
                      expiryDates();
                      description = '';
                      errorForm = false;
                      dialog = false;
                    "
                  >
                    {{ labels.MDL_CLS_TXT }}
                  </v-btn>
                  <v-btn
                    large
                    color="default-btn"
                    class="secondary darken-3 white--text text-capitalize"
                    text
                    @click="updateCredit()"
                  >
                    {{ labels.MDL_ADD_TXT }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-row>
        <!-------------------Alert------------------------------>
        <v-row class="justify-center gp1">
          <v-dialog v-model="promo" max-width="403" persistent>
            <v-card class="pa-6 text-center">
            
                <div class="text-right primarycolor">   
                  <v-icon 
                    @click="promo = false"
                    class="cursor-pointer lightclose">mdi-close-thick</v-icon>
                  <i
                    
                    
                  ></i>
                </div>
                <h4
                  class="headline head-com-1 mb-1 font-weight-bold text-center pb-5"
                >
                  {{ labels.PROMO_MSG_TIT }}
                </h4>
              
              <div class="px-6">
                <v-text-field
                  class="promo credit_prom"
                  v-model="promocode"
                  :label="labels.PROMO"
                  solo
                  flat
                  outlined
                ></v-text-field>
              </div>
              <div class="minu-m-20" v-if="promoerror">
                <v-icon class="error--icontext error--text">mdi-alert-circle</v-icon>
                <span class="error--text"> {{ errorPromoMsg }}</span>
              </div>
              <div class="text-center pa-2 pt-6">
                <v-btn
                  text
                  @click="usePromo()"
                  class="secondary darken-3 white--text text-capitalize"
                >
                  {{ labels.PROMO_SUBMIT }}
                  <v-progress-circular
                    :width="3"
                    :size="15"
                    color="white"
                    indeterminate
                    class="ml-1"
                    v-if="promores"
                  ></v-progress-circular>
                </v-btn>
              </div>
            </v-card>
          </v-dialog>
        </v-row>
        <!-------------------End------------------------------>
        <!-------------------Success Promocode applied------------------------------>
        <v-row class="justify-center gp1">
          <v-dialog v-model="promoCodeApplied" max-width="450" persistent>            
            <v-card class="pa-6 text-center"> 
              <div class="text-right primarycolor">
                <v-icon 
                    @click="promoCodeApplied = false"
                    class="cursor-pointer lightclose">mdi-close-thick</v-icon>
              </div>
              <div class="pb-5">
                <div class="d-flex text-center align-center justify-center">
                  <v-icon class="font-xxx succ-text-green">mdi-check-circle-outline</v-icon>
                  <h3
                    class="headline letter-spacing-1 head-com-1 pl-3 mb-1 font-weight-bold text-center succ-text-green"
                  >
                    {{ labels.PROMO_APPLIED_TIT }}
                  </h3>
                </div>
              </div>
              <div class="px-6">
                <div class="d-flex text-center align-center justify-center">
                  {{ labels.CREDITS_ADD_MSG
                  }}<span class="font-weight-bold pr-2 pl-1">
                    USD {{ promovalue | formatNumber2}}</span
                  >
                  <!-- <v-img
                    width="25"
                    max-width="25"
                    alt="credit"
                    class=""
                    contain
                    src="@/assets/dollar-img.png"
                  ></v-img> -->
                </div>
              </div>
              <div class="px-6 pt-6">
                <div class="text-center primarytextcolor--text text--darken-3">
                  {{ labels.VALID_UNIT_TXT }}
                  <span class="font-weight-bold pl-1">{{ promoValidity }}</span>
                </div>
              </div>
              <div class="text-center pa-2 pt-8">
                <v-btn
                  text
                  @click="promoCodeApplied = false"
                  class="secondary darken-3 white--text text-capitalize px-8"
                >
                  {{ labels.UP_MDL_CLS_TXT }}
                </v-btn>
              </div>
            </v-card>
          </v-dialog>
        </v-row>
        <!-------------------End------------------------------>
        <v-overlay :value="overlay" z-index="6" color="#cececea6">
          <v-img
            alt="sentient logo"
            class="shrink mt-1"
            :contain="true"
            src="@/assets/loader.gif"
            width="200"
          ></v-img>
        </v-overlay>
      </v-container>
      <!-- <Svgicons /> -->
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
// import Svgicons from "@/SVG/Svg-icon.vue";
import "@mdi/font/css/materialdesignicons.css";
// import Footer from "./Footer.vue";
import { FlexiService } from "../services/Flexi_Services";
import { CreditmanagementService } from "../services/Creditmanagement_Services";
const { getLabels, getFlexList, filterErrorList } = FlexiService();
const {
  getList,
  getCreditTypes,
  filterList,
  getOrgList,
  getMainOrgList,
  getOrgAccount,
  filterAccount,
  updateCredit,
  getExpiryDate,
  usePromo,
  updateSearchHistory,
} = CreditmanagementService();
var numeral = require("numeral");
export default {
  name: "CreditManagement",
  components: {
    // Svgicons,
    // Footer,
  },
  data: () => ({
    overlay: true,
    dialog: false,
    promo: false,
    lang: "EN",
    labels: {},
    orgType: "",
    teamspace: false,
    apiKey: "",
    orgId: 0,
    ownerId:0,
    acId: "",
    startDate: "",
    expiryDate: "",
    orgname: "",
    credit: "",
    description: "",
    activeCredits: [],
    expiryCredits: [],
    filterListActive: [],
    filterListExpiry: [],
    showActive: true,
    creditTypes: [{ code: "", value: "" }],
    itemTypes: [],
    creditType: "",
    orgList: [],
    ownerList:[],
    accountData: {},
    errorForm: false,
    promocode: "",
    promoerror: false,
    promoErrors: {},
    promores: false,
    errorPromoMsg: "Invalid promo code",
    promoCodeApplied: false,
    promovalue: 0,
    promoValidity: "",
    headers: [],
    date: new Date().toISOString().substr(0, 10),
    date1: new Date().toISOString().substr(0, 10),
    menu: false,
    menu1: false,
    user_id:0,
    org_id:0,
    email:"",
  }),
  created: function () {
    // Session Variable set
    var session = this.$session.get("user_session");
    session = session.session;
    // window.document.title ='Credits Management'+' | '+session.owner_name;
    this.apiKey = session.userApiKey;
    this.orgId = session.org;
    this.ownerId=session.owner,
    this.orgType = session.orgType;
    this.org_id=session.org;
    this.user_id=session.id;
    this.email=session.email;
    this.teamspace=session.teamspace;
    // this.lang = this.$session.get("lang");
    this.uAccess = session.uAccess;
    this.getFlexi(this.lang);
    this.getOrgList(this.ownerId);
    this.getMainOrgList();
    this.getFlexList();
    this.startDates();
    this.expiryDates();
  },
  methods: {
    async getCreditList() {
      const response = await getList(this.apiKey, {
        orgId: this.orgId + "",
        acId: this.acId + "",
      })
        .then((response) => response.data)
        .catch((err) => console.log(err));
      this.overlay = false;
      this.activeCredits = response.results.activecredits;
      this.expiryCredits = response.results.expirycredits;
      this.filterListActive = this.activeCredits;
      this.filterListExpiry = this.expiryCredits;
    },
    async startDates () {
      var todaydate = new Date();
      var current_month = new Date(todaydate.setMonth(todaydate.getMonth()));
      var day = ("0" + current_month.getDate()).slice(-2);
      var month = ("0" + (current_month.getMonth()+1)).slice(-2);
      this.startDate = current_month.getFullYear() + "-" + (month) + "-" + (day);
    },
    async expiryDates () {
      var todaydate = new Date();
      var current_month = new Date(todaydate.setMonth(todaydate.getMonth()+6));
      var day = ("0" + current_month.getDate()).slice(-2);
      var month = ("0" + (current_month.getMonth() + 1)).slice(-2);
      this.expiryDate = current_month.getFullYear() + "-" + (month) + "-" + (day);
    },
    async updateCredit() {
      this.errorForm = false;
      if (this.creditType == "" || this.credit == "" || this.credit == 0) {
        this.errorForm = true;
      }
      if (!this.errorForm) {
        this.dialog = false;
        this.overlay = true;
        const response = await updateCredit(this.apiKey, {        
          orgId: this.orgId,
          acId: this.acId + "",
          description: this.description,
          credit: this.credit,
          orgname: this.orgname,
          creditType: this.creditType,
          startDate: this.startDate,
          expiryDate: this.expiryDate,
        })
          .then((response) => response.data)
          .catch((err) => console.log(err));
        this.dialog = false;
        this.creditType='';
        this.credit='';
        this.startDate= new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate();
        this.expiryDates();
        this.description='';
        this.getCreditList();
        console.log(response.ststus);
      }
    },
    async getCreditTypes() {
      var self = this;
      const response = await getCreditTypes(this.apiKey, {
        owner_id:this.ownerId,
        lang: this.lang,
        code: "CREDIT_TYPE",
      })
        .then((response) => response.data)
        .catch((err) => console.log(err));
      self.creditTypes = response.result;
      self.creditTypes.unshift({ code: "", value: self.labels.SELECT_TYPE });
      self.itemTypes = self.creditTypes;
    },
    async getOrgList() {
      const response = await getOrgList(this.apiKey,{owner_id:this.ownerId})
        .then((response) => response.data)
        .catch((err) => console.log(err));
      if (response.status == "success") {
        this.orgList = response.results;
        this.orgId = this.orgList[0].id;
      }
      this.getOrgAccount();      
    },
    async getMainOrgList() {
      const response = await getMainOrgList(this.apiKey, {})
        .then((response) => response.data)
        .catch((err) => console.log(err));
      if (response.status == "Success") {
        this.ownerList = response.results;
      }
    },
    async getOrgAccount() {
      this.overlay = true;
      const response = await getOrgAccount(this.apiKey, { orgId: this.orgId, lang: this.lang })
        .then((response) => response.data)
        .catch((err) => console.log(err));
      this.accountData = await filterAccount(response.result, "Spending");
      this.orgId = this.accountData.orgId;
      this.acId = this.accountData.id;
      this.getCreditList();
    },
    async resultQuery() {
      this.filterListActive = await filterList(
        this.activeCredits,
        this.creditType
      );
      this.filterListExpiry = await filterList(
        this.expiryCredits,
        this.creditType
      );
    },
    getExpiryDate(date) {
      return getExpiryDate(date);
    },
    async usePromo() {
      this.promoerror = false;
      if (this.promocode == "") {
        this.promoerror = true;
      } else {
        this.promores = true;
        const response = await usePromo(this.apiKey, {
          orgId: this.orgId,
          code: this.promocode,
          codefrom:'usepromo'
        })
          .then((response) => response.data)
          .catch((err) => console.log(err));
        let msg = response.message;
        if (response.status == "Success") {
          this.promo = false;
          this.promovalue = response.promocredits;
          this.promoValidity = response.promoexpiry;
          this.promoCodeApplied = true;
          this.getCreditList();
        } else {
          this.promoerror = true;
          if (msg == "EXPIRED" || msg == "ALREADY_USED" || msg == "INVALID") {
            this.errorPromoMsg = this.promoErrors[msg];
          } else {
            this.errorPromoMsg = this.labels.DIFFCODE;
          }
        }
        this.promores = false;
      }
    },
    goTopup() {
      localStorage.toupdata = JSON.stringify(this.accountData);
      this.addSearchHistory();
      setTimeout(() => location.href = "topup", 500);
    },
    addSearchHistory(){
        updateSearchHistory(this.apiKey, { org_id: this.org_id, user_id: this.user_id,email:this.email})
        .then((response) => response.data)
        .catch((err) => console.log(err));
    },
    async getFlexList() {
      const response = await getFlexList(this.apiKey, {
        owner_id:this.ownerId,
        lang: this.lang,
        code: "PROMO_REFERRAL_ERROR_MSG",
      })
        .then((response) => response.data)
        .catch((err) => console.log(err));
      this.promoErrors = await filterErrorList(response.result);
    },
    async getFlexi(lang) {
      const response = await getLabels(this.apiKey, {
        owner_id:this.ownerId,
        scrId: "CREDITEXPIRY",
        lang:lang,
      })
        .then((response) => response.data)
        .catch((err) => console.log(err));
      this.labels = response.labels;
      this.headers = [
        { text: this.labels.TAB_CREDIT_TYPE },
        { text: this.labels.TAB_DESCRIPTION },
        { text: "Start Date"},
        { text: this.labels.TAB_EXP_DATE },
        { text: this.labels.TAB_CREDIT,align :'end'},
        { text: this.labels.TAB_BAL_CREDIT,align :'end' },
        { text: this.labels.TAB_PRIORITY },
      ];
      this.creditTypes = [{ code: "", value: this.labels.SELECT_TYPE }];
      this.getCreditTypes();      
    },
  },
  watch: {
    date1(val) {
      this.startDate = val;
    },
    date(val) {
      this.expiryDate = val;
    },
  },
  filters: {
    formatNumber: function (value) {
      return numeral(parseFloat(value)).format("0,0.0000");
    },
    formatNumber2: function (value) {
      return numeral(parseFloat(value)).format("0,0.00");
    }
  },
};
</script>
<style scoped>
  .main-credit {
    padding: 12px 48px;
  }
.minu-m-20 {
  margin-top: -20px;
  font-size: 13px;
}
.succes-circle {
  font-size: 45px;
  padding: 16px;
  background-color: #f8ffdc !important;
  border-radius: 100%;
}
::v-deep .v-input__icon{
  height: 24px !important;
}
.errorCircle {
  display: inline-block;
  padding: 14px 22px;
  border-radius: 100%;
  font-size: 27px;
  border-color: red;
  border: 5px solid;
}
.errorflex-box {
  display: inline-flex;
  background-color: #a002020f !important;
}
.container {
  max-width: 1224px;
}
.font-xxx {
  color: #a4c327;
  font-size: 53px;
}
.fa-times {
    font-weight: 700;
    font-size: 22px;
    color: #9e9e9eb3;
}
.succ-text-green{
  color:#a4c327!important;
}
.topnav{
  display: flex;
  flex-direction: row;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
.creditmng_table table thead tr th{
  font-size: 14px !important;
}
.error--icontext{
  font-size: 15px;
}

.dropheight .v-list-item{
  min-height : 48px !important;
}
.text-color{
  color: rgba(0,0,0,.38);
}
@media only screen and (max-width: 560px) {
  .topnav{
    align-items:flex-start;
    width: 100%;
    display:flex;
    flex-direction: column;
  }
}
.padding{
  padding:10px;
  height: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .topnav {
    display: block;
  }
}
/* ---------------------------------- mobile view ---------------------------------- */
@media only screen and (min-width: 350px) and (max-width: 767px) {
  .main-credit {
    padding: 0;
  }
  .topnav {
    display: block;
  }
  .padding {
    height: auto;
  }
  .crd-btns {
    width: 100%;
    
  }
  .crd-btns .v-btn{
      width: 100%;
    }
}
.padding-align{
  padding-left : 0px;
}
</style>