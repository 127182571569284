<template>
  <div class="mbl-msform msForm">
    <v-row>     
        <v-col cols="12" sm="12">
            <label>Status</label>
            <v-select
            outlined
            v-model="outageitem.status"
            :items = "statustype"
            ></v-select>
            <span v-if="!outageitem.status && !isValidForm" style="color:red;">Status is required</span>   
        </v-col>   
        
        <v-col cols="12" sm="6">
            <label>Start date</label>
            <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    outlined
                    v-model="outageitem.start_date_time"
                    solo
                    flat
                    label="Select Start Date"
                    append-icon="mdi mdi-calendar-today"
                    v-on:click:append="menu1 = true"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
            </template>
            <v-card>
                <v-date-picker
                    v-model="start_date"
                    @input="dateSelected"
                ></v-date-picker>
                <v-time-picker
                    v-if="timePickerVisible"
                    v-model="start_time"
                    format="24hr"
                    @change="startTimeSelected"
                ></v-time-picker>
                <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn text @click="menu2 = false">Cancel</v-btn>
                    <v-btn text @click="saveDateTime">OK</v-btn>
                </v-card-actions>
            </v-card>
            </v-menu>
        </v-col>
        
        <v-col cols="12" sm="6">
            <label>End Date</label>
            <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    outlined
                    v-model="outageitem.end_date_time"
                    solo
                    flat
                    label="Select Expiry Date"
                    append-icon="mdi mdi-calendar-today"
                    v-on:click:append="menu2 = true"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
            </template>
            <v-card>
                <v-date-picker
                    v-model="end_date"
                    @input="dateSelected"
                ></v-date-picker>
                <v-time-picker
                    v-if="timePickerVisible"
                    v-model="end_time"
                    format="24hr"
                    @change="endTimeSelected"
                ></v-time-picker>
                <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn text @click="menu2 = false">Cancel</v-btn>
                    <v-btn text @click="saveDateTime">OK</v-btn>
                </v-card-actions>
            </v-card>
            </v-menu> 
        </v-col>
        
        <v-col cols="12">
            <label>Outage</label>
            <mavon-editor 
                language="en" 
                v-model="outageitem.outage" 
                outlined 
                :subfield="false"
                name="input-7-1"
                rows="3"
            />
            <span v-if="!outageitem.outage && !isValidForm" style="color:red;">Outage is required</span> 
        </v-col>
        
    </v-row>
  </div>
</template>

<script>
// import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
export default {
  name: "addoutage",
  props: ["outageitem","isValidForm"],
  data: () => ({ 
    start_date: new Date().toISOString().substr(0, 10),
    end_date: new Date().toISOString().substr(0, 10),
    start_time:null,
    end_time :null,
    timePickerVisible: false,
    menu1 : false,
    menu2 : false,
    currentDate : "",
    statustype: ["Active", "Closed"],
  }),
  created: function () {
    this.startDates();
    this.expiryDates();
  },
  methods:{
    dateSelected() {
        this.timePickerVisible = true;
    },
    startTimeSelected() {
        this.menu1 = false;
        this.saveStartDateTime();
    },
    endTimeSelected() {
        this.menu2 = false;
        this.saveEndDateTime();
    },
    saveStartDateTime() {
        if (this.start_date && this.start_time) {
            const dateParts = this.start_date.split('-');
            this.start_date = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
            this.outageitem.start_date_time = this.start_date+" "+this.start_time+ ":00"
            console.log(this.outageitem.start_date_time)
            this.menu1 = false;
            this.timePickerVisible = false;
        }
    },
    saveEndDateTime() {
        if (this.end_date && this.end_time) {
            const dateParts = this.end_date.split('-');
            this.end_date = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
            this.outageitem.end_date_time = this.end_date+" "+this.end_time+ ":00"
            console.log(this.outageitem.end_date_time)
            this.menu2 = false;
            this.timePickerVisible = false;
        }
    }
},
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>